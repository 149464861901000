<template>
    <div class="txt txt--sm txt--dark font-weight-bold">
        {{ coupon.name }}
    </div>
</template>

<script>
import { initShopCoupon } from "@/assets/variables";

export default {
    props: {
        coupon: { type: Object, default: initShopCoupon },
    },
};
</script>

<style></style>
