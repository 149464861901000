var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.carts.length ? _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, _vm._l(_vm.carts, function (cart, index) {
    return _c('div', {
      key: index,
      class: {
        'mt-4px mt-md-8px': index != 0
      }
    }, [_c('carts-item', _vm._g(_vm._b({}, 'carts-item', {
      cart,
      index
    }, false), {
      incAmount: _vm.incAmount,
      decAmount: _vm.decAmount,
      changeAmount: _vm.changeAmount,
      remove: _vm.remove
    }))], 1);
  }), 0) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }