<template>
    <item-dialog v-bind="{ coupon }">
        <template #activator="{ attrs, on }">
            <v-card outlined class="card-coupon fill-height overflow-hidden" v-bind="{ ...attrs, loading, disabled }" @click="on.click">
                <v-row no-gutters align="stretch" class="fill-height">
                    <v-col cols="12" md="">
                        <div class="d-flex flex-column justify-space-between fill-height pa-12px pa-md-20px">
                            <item-name v-bind="{ coupon }" class="mb-12px" />
                            <item-price v-bind="{ coupon }" />
                            <item-desc v-bind="{ coupon }" class="font-size-12 grey--text mt-12px" />
                        </div>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div v-bind="{ loading }" :class="disabled ? 'grey' : 'grey lighten-5'" class="d-flex flex-column justify-center text-center h-100 pa-12px" @click.stop.capture.prevent="post">
                            <template v-if="disabled"> <span class="font-size-14 font-weight-bold white--text"> 이미 받은 쿠폰입니다 </span> </template>
                            <template v-else> <v-icon large color="primary"> mdi-download </v-icon> </template>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </item-dialog>
</template>

<script>
import api from "@/api";
import { initShopCoupon } from "@/assets/variables";

import ItemName from "./item/item-name.vue";
import ItemDesc from "./item/item-desc.vue";
import ItemPrice from "./item/item-price.vue";
import ItemDialog from "./item/item-dialog.vue";
import { mapState } from "vuex";

export default {
    components: {
        ItemName,
        ItemDesc,
        ItemPrice,
        ItemDialog,
    },

    props: {
        coupon: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        loading: false,
        issuedCount: 0,
    }),
    computed: {
        ...mapState({ _user: (state) => state?.payload?._user }),
        disabled() {
            const issueCountExceeded = this.coupon.count <= this.issuedCount;
            return issueCountExceeded;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        coupon() {
            this.init();
        },
        _user() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            this.issuedCount = 0;
            try {
                if (this._user) {
                    const { summary } = await api.v1.me.coupons.gets({
                        headers: { mode: "summary" },
                        params: { _coupon: this.coupon._id },
                    });
                    this.issuedCount = summary.totalCount;
                }
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        async post() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { coupon } = await api.v1.me.coupons.post({
                    _coupon: this.coupon._id,
                });
                this.issuedCount += 1;
                this.loading = false;
                this.init();
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.card-coupon.v-card--disabled {
    pointer-events: auto;
    user-select: auto;
    cursor: pointer;
    opacity: 0.3;
    & > :not(.v-card__progress) {
        opacity: 1;
    }
    &.theme--light {
        &.v-card {
            color: var(--default-color);
        }
        &.v-sheet--outlined {
            border: 1px solid var(--border-color);
        }
    }
}
</style>
