<template>
    <page-section class="page-section--xs pb-0" v-if="carts.length">
        <div v-for="(cart, index) in carts" :key="index" :class="{ 'mt-4px mt-md-8px': index != 0 }">
            <carts-item v-bind="{ cart, index }" v-on="{ incAmount, decAmount, changeAmount, remove }" />
        </div>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";
import CartsItem from "./carts/carts-item.vue";

export default {
    components: {
        PageSection,
        CartsItem,
    },
    props: {
        value: { type: Array, default: () => [] }, // selected
        clearable: { type: Boolean, default: false },
    },
    data: () => ({
        carts: [],
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.carts = [...this.value];
        },
        emit() {
            this.$emit("input", this.carts);
        },
        incAmount(cart) {
            if (cart.amount < cart.stock) cart.amount++;
            this.emit();
        },
        decAmount(cart) {
            if (1 < cart.amount) cart.amount--;
            this.emit();
        },
        changeAmount(cart) {
            if (cart.amount < 1) cart.amount = 1;
            if (cart.stock < cart.amount) cart.amount = cart.stock;
            this.emit();
        },
        remove(index) {
            this.carts.splice(index, 1);
            this.emit();
        },
    },
};
</script>

<style></style>
