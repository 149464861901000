var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('item-dialog', _vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-card', _vm._b({
          staticClass: "card-coupon fill-height overflow-hidden",
          attrs: {
            "outlined": ""
          },
          on: {
            "click": on.click
          }
        }, 'v-card', Object.assign({}, attrs, {
          loading: _vm.loading,
          disabled: _vm.disabled
        }), false), [_c('v-row', {
          staticClass: "fill-height",
          attrs: {
            "no-gutters": "",
            "align": "stretch"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": ""
          }
        }, [_c('div', {
          staticClass: "d-flex flex-column justify-space-between fill-height pa-12px pa-md-20px"
        }, [_c('item-name', _vm._b({
          staticClass: "mb-12px"
        }, 'item-name', {
          coupon: _vm.coupon
        }, false)), _c('item-price', _vm._b({}, 'item-price', {
          coupon: _vm.coupon
        }, false)), _c('item-desc', _vm._b({
          staticClass: "font-size-12 grey--text mt-12px"
        }, 'item-desc', {
          coupon: _vm.coupon
        }, false))], 1)]), _c('v-col', {
          attrs: {
            "cols": "12",
            "md": "4"
          }
        }, [_c('div', _vm._b({
          staticClass: "d-flex flex-column justify-center text-center h-100 pa-12px",
          class: _vm.disabled ? 'grey' : 'grey lighten-5',
          on: {
            "!click": function ($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return _vm.post.apply(null, arguments);
            }
          }
        }, 'div', {
          loading: _vm.loading
        }, false), [_vm.disabled ? [_c('span', {
          staticClass: "font-size-14 font-weight-bold white--text"
        }, [_vm._v(" 이미 받은 쿠폰입니다 ")])] : [_c('v-icon', {
          attrs: {
            "large": "",
            "color": "primary"
          }
        }, [_vm._v(" mdi-download ")])]], 2)])], 1)], 1)];
      }
    }])
  }, 'item-dialog', {
    coupon: _vm.coupon
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }