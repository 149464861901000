var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.coupon.name) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }