<template>
    <page-section class="page-section--xs pb-0" v-if="criterion">
        <tit-wrap-x-small title="색상선택" />
        <div class="pb-12px">
            <div class="txt txt--xs">{{ colorName }}</div>
        </div>
        <v-btn-toggle v-model="selected[criterionName]" mandatory group class="btn-group btn-group--color" @change="emit">
            <template v-for="item in items">
                <v-btn :value="item.name" x-small icon :key="item.name">
                    <template v-if="item.img">
                        <div class="square-img" :style="`background-image: url(${item.img.url})`" />
                    </template>
                    <template v-else>
                        <div class="btn-group__colorchip" :style="`background-color: ${item.hexa}`" />
                    </template>
                </v-btn>
            </template>
        </v-btn-toggle>
    </page-section>
</template>

<script>
import { PRODUCT_OPTION_CRITERIA_TYPES } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

export default {
    components: {
        PageSection,
        TitWrapXSmall,
    },
    props: {
        value: { type: Object, default: () => ({}) },
        criteria: { type: Array, default: () => [] },
    },
    data: () => ({
        selected: {},
    }),
    computed: {
        criterion() {
            return this.criteria.find(({ type }) => type == PRODUCT_OPTION_CRITERIA_TYPES.COLOR.value);
        },
        criterionName() {
            return this.criterion?.name;
        },
        items() {
            return this.criterion?.values || [];
        },
        color() {
            return this.items.find(({ name }) => name == this.selected[this.criterionName]);
        },
        colorName() {
            return this.color?.name;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.selected = { ...this.value };
        },
        emit() {
            for (const name of Object.keys(this.selected)) {
                if (name != this.criterionName) delete this.selected[name];
            }
            this.$emit("input", this.selected);
        },
    },
};
</script>

<style lang="scss" scoped>
// btn-group
.btn-group {
    flex-wrap: wrap;
    margin: -4px;
    .v-btn {
        line-height: 1;
        opacity: 1;
        &.v-size--x-small.v-btn--icon {
            position: relative;
            width: 34px !important;
            height: 34px !important;
            padding: 0 !important;
        }
    }
    &--color {
        .v-btn {
            border-radius: 100% !important;
            overflow: hidden;
            border: 0 !important;
            &--active {
                &::before {
                    display: none;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    border: 2px solid var(--v-grey-darken4) !important;
                }
            }
        }
    }
    &__colorchip {
        width: 30px;
        height: 30px;
        border-radius: 100%;
    }
}
</style>
