var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": '타겟 ' + _vm.coupon.usage.target.typeText + ' 할인 쿠폰',
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('page-section', {
    staticClass: "page-section--xs pt-0"
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v(_vm._s(_vm.coupon.usage.target.priceText) + " 적용가능")])]), _c('page-section', {
    staticClass: "page-section--xs py-0"
  }, [_vm.coupon.usage.target.type == _vm.USAGE_TARGET_TYPES.ALLITEMS.value ? [_c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px txt txt--xs"
  }, [_c('div', {
    staticClass: "txt txt--xs txt--dark font-weight-bold mb-8px"
  }, [_vm._v("적용가능 상품")]), _c('div', [_vm._v("전체 상품 사용가능")])])])] : _vm._e(), _vm.coupon.usage.target.type == _vm.USAGE_TARGET_TYPES.PRODUCTS.value ? [_c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px txt txt--xs"
  }, [_c('div', {
    staticClass: "txt txt--xs txt--dark font-weight-bold mb-8px"
  }, [_vm._v("적용가능 상품")]), _c('div', [_vm._v("상품 상세 별도표기")])])])] : _vm._e(), _vm.coupon.usage.target.type == _vm.USAGE_TARGET_TYPES.DISPLAYS.value ? [_c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px txt txt--xs"
  }, [_c('item-dialog-displays', _vm._b({}, 'item-dialog-displays', {
    coupon: _vm.coupon
  }, false))], 1)])] : _vm._e(), _vm.coupon.usage.target.type == _vm.USAGE_TARGET_TYPES.EXHIBITS.value ? [_c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px txt txt--xs"
  }, [_c('item-dialog-exhibits', _vm._b({}, 'item-dialog-exhibits', {
    coupon: _vm.coupon
  }, false))], 1)])] : _vm._e(), _vm.coupon.usage.target.type == _vm.USAGE_TARGET_TYPES.CATEGORY.value ? [_c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px txt txt--xs"
  }, [_c('item-dialog-categories', _vm._b({}, 'item-dialog-categories', {
    coupon: _vm.coupon
  }, false))], 1)])] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }