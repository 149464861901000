<template id="modal-template">
    <u-dialog v-model="show" name="modal" title="장바구니" max-width="400">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <div class="py-10px">
            <div class="txt txt--sm txt--dark text-center">선택하신 상품을 장바구니에 담았습니다.</div>
        </div>

        <div class="btn-wrap">
            <v-row class="row--sm">
                <v-col>
                    <v-btn v-bind="{ ...btn_secondary, ...$attrs }" block class="v-size--xx-large" @click="close">쇼핑계속하기</v-btn>
                </v-col>
                <v-col>
                    <v-btn v-bind="{ ...btn_primary, ...$attrs }" block to="/mypage/cart" class="v-size--xx-large">장바구니 가기</v-btn>
                </v-col>
            </v-row>
        </div>
    </u-dialog>

    <!-- <transition v-if="show" name="modal" appear>
        <div class="modal-container">
            <div class="modal-wrapper">
                <div class="modal modal--6">
                    <button class="modal__close" type="button" @click="close">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path d="M0.222,1.636 L1.636,0.221 L15.778,14.363 L14.364,15.778 L0.222,1.636 Z" />
                            <path d="M14.364,0.221 L15.778,1.636 L1.636,15.778 L0.222,14.363 L14.364,0.221 Z" />
                        </svg>
                    </button>
                    <div class="modal__body modal__body--top">
                        <div class="modal-message">
                            <span class="modal-message__icon">
                                <i class="icon icon-cart-added"></i>
                            </span>
                            <p class="modal-message__pr">선택하신 상품을 장바구니에 담았습니다.</p>
                        </div>
                    </div>
                    <div class="modal__foot modal__foot-no-border">
                        <div class="modal__buttons modal__buttons--narrow">
                            <a class="button button--border-primary" href="" onclick="return false;" @click="close"><span>쇼핑계속하기</span></a>
                            <router-link class="button button--primary" to="/mypage/cart"><span>장바구니 가기</span></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition> -->
</template>

<script>
import { btn_primary, btn_secondary } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";

export default {
    components: {
        UDialog,
    },
    data: () => {
        return {
            btn_primary,
            btn_secondary,

            show: false,
        };
    },
    methods: {
        open() {
            this.show = true;
            // document.querySelector("html").setAttribute("data-modal", "opened");
        },
        close() {
            this.show = false;
            // document.querySelector("html").removeAttribute("data-modal");

            let toggle = document.querySelector(".product-sidebar__close");
            document.querySelector(".product-details__sidebar")?.removeAttribute?.("data-sidebar-mobile");
            toggle?.setAttribute?.("aria-pressed", "false");
        },
    },
};
</script>
